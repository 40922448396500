/* 05.04 Header Logo CSS */

.header-logo {
    & a {
        position: relative;

        max-width: 158px;
        
        & img {
            max-width: 120px;
            padding: 15px 0;
            margin-left: 1rem !important;

            &.light-logo {
                position: absolute;
                top: 0;
                left: 0;

                opacity: 0;
            }


        }
    }

    @media #{$extra-small-mobile} {
        & a {
            max-width: 120px;
        }

    }
}


.header-light {
    & .header-logo {
        & a {
            & img {
                &.light-logo {
                    opacity: 1;
                }

                &.dark-logo {
                    opacity: 0;
                    max-width: 500px;
                    height: 100px;
                }
            }
        }
    }
}

// @media #{$tablet-device}{
//     .headerLogo{
//         margin-left: -40px;
//         height: 100px;
//     }
//     .headerLogo img {
//         width: 130px;
//         height: 160px;
//         margin-top: -30px;
//     }
//     .header-logo{
//         width: 250px;
//         margin-left: -20px;
//     } 
// }
// @media #{$large-mobile}{
//     .headerLogo{
//         margin-left: -40px;
//         height: 50px !important;
//     }
//     .headerLogo img {
//         width: 100px;
//         height:130px;
//         margin-top: -40px;
//     }

// }
// @media #{$extra-small-mobile}{
//     .headerLogo{
//         margin-left: -2rem;
//         height: 50px;

//     }

// }
// @media #{$extraBig-device}{
//     .headerLogo{
//         margin-left: -250px;
//     }
// }
@media only screen and (max-width: 2048px) {
    .headerLogo {
        margin-left: -3rem;
    }
}


.logoCustom{
    max-height: 11rem;
}